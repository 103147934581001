var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$store.state.devicesError.length)?_c('div',[_c('p',{staticClass:"text-right"},[_vm._v(" Total Devices: "+_vm._s(_vm.$store.state.devices.length)+" | Updated at: "+_vm._s(new Date().toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true }))+" | "),_c('EditColumns',{attrs:{"brandColumns":this.config.columns,"columnPreferences":this.columns,"localStorageName":"COLUMN_PREFERENCES_DEVICES"},on:{"refresh":function($event){return _vm.getColumns()}}})],1),_c('vue-good-table',{attrs:{"data-cy":"devices-table","title":"Devices Tables","columns":this.columns,"rows":_vm.$store.state.devices,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 100,
        position: 'both',
        perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
        dropdownAllowAll: false,
        nextLabel: 'next',
        prevLabel: 'prev',
        rowsPerPageLabel: 'Rows per page',
        ofLabel: 'of',
        pageLabel: 'page',
        allLabel: 'All'
      },"search-options":{
        enabled: true
      },"sort-options":{
        enabled: true
      },"line-numbers":true},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.type == 'colorHex')?_c('div',{staticClass:"text-center"},[_c('ColorDot',{attrs:{"colorHex":props.row[props.column.field],"columnName":props.column.field}}),_c('p',[_vm._v(_vm._s(props.row[props.column.field]))])],1):(props.column.type === 'colorArray')?_c('div',{staticClass:"text-center"},[_c('ColorDot',{attrs:{"colorArray":props.row[props.column.field],"columnName":props.column.field}}),_c('p',[_vm._v(_vm._s(props.row[props.column.field]))])],1):(
            props.column.field !== 'deviceId' &&
              props.column.type !== 'AWSDateTime'
          )?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]):_vm._e(),(props.column.field === 'deviceId')?_c('a',{attrs:{"href":"#","data-cy":"device-detail-link"}},[_vm._v(_vm._s(props.row.deviceId))]):(props.column.field === 'delete')?_c('div',[_c('button',{staticClass:"cursor-pointer",attrs:{"data-cy":"delete-device-btn"}},[_vm._v(" DELETE ")])]):_vm._e(),(props.column.type === 'AWSDateTime')?_c('div',{staticClass:"text-center"},[_c('TwoDates',{attrs:{"date":props.row[props.column.field]}})],1):_vm._e()]}}],null,false,3843238632)},[_c('div',{staticClass:"emptystate",attrs:{"slot":"emptystate","data-cy":"empty-state"},slot:"emptystate"},[_c('EmptyTable',{attrs:{"loading":_vm.$store.state.loading,"type":"Devices"}})],1)]),(_vm.deleteModal)?_c('DeleteDevice',{on:{"close":function($event){return _vm.showDeleteModal(false)}}}):_vm._e()],1):_vm._e(),_c('TableErrors',{attrs:{"errors":_vm.$store.state.devicesError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }