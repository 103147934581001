<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">Devices</h1>
    <CreateDeviceForm />
    <DevicesTable />
  </div>
</template>

<script>
import DevicesTable from '@/components/devices/DevicesTable.vue';
import CreateDeviceForm from '@/components/forms/CreateDeviceForm.vue';

export default {
  components: {
    CreateDeviceForm,
    DevicesTable
  }
};
</script>
