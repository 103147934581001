<template>
  <div>
    <div v-if="!$store.state.devicesError.length">
      <p class="text-right">
        Total Devices: {{ $store.state.devices.length }} | Updated at:
        {{
          new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          })
        }}
        | <EditColumns
            :brandColumns="this.config.columns"
            :columnPreferences="this.columns"
            localStorageName="COLUMN_PREFERENCES_DEVICES"
            @refresh="getColumns()"
            />
      </p>
      <vue-good-table
        data-cy="devices-table"
        title="Devices Tables"
        :columns="this.columns"
        :rows="$store.state.devices"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 100,
          position: 'both',
          perPageDropdown: [10, 50, 100, 250, 500, 1000, 10000],
          dropdownAllowAll: false,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        :search-options="{
          enabled: true
        }"
        @on-cell-click="onCellClick"
        :sort-options="{
          enabled: true
        }"
        :line-numbers="true"
      >
        <div slot="emptystate" class="emptystate" data-cy="empty-state">
          <EmptyTable :loading="$store.state.loading" type="Devices" />
        </div>
        <template slot="table-row" slot-scope="props">
          <div class="text-center" v-if="props.column.type == 'colorHex'">
            <ColorDot :colorHex="props.row[props.column.field]" :columnName="props.column.field"
            />
            <p>{{ props.row[props.column.field] }}</p>
          </div>
          <div class="text-center" v-else-if="props.column.type === 'colorArray'">
            <ColorDot :colorArray="props.row[props.column.field]" :columnName="props.column.field"
            />
            <p>{{ props.row[props.column.field] }}</p>
          </div>
          <span
            v-else-if="
              props.column.field !== 'deviceId' &&
                props.column.type !== 'AWSDateTime'
            "
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
          <a
            href="#"
            v-if="props.column.field === 'deviceId'"
            data-cy="device-detail-link"
            >{{ props.row.deviceId }}</a
          >
          <div v-else-if="props.column.field === 'delete'">
            <button
              class="cursor-pointer"
              data-cy="delete-device-btn"
            >
              DELETE
            </button>
          </div>
          <div class="text-center" v-if="props.column.type === 'AWSDateTime'">
            <TwoDates :date="props.row[props.column.field]" />
          </div>
        </template>
      </vue-good-table>
      <DeleteDevice v-if="deleteModal" @close="showDeleteModal(false)" />
    </div>
    <TableErrors v-bind:errors="$store.state.devicesError" />
  </div>
</template>

<script>
import TableErrors from '@/components/UI/TableErrors.vue';
import EmptyTable from '@/components/UI/EmptyTable.vue';
import brandConfig from '@/util/brandConfigs';
import currentBrand from '@/util/getCurrentBrand';
import DeleteDevice from '@/components/devices/DeleteDevice.vue';
import ColorDot from '@/components/UI/ColorDot.vue';
import TwoDates from '@/components/UI/TwoDates.vue';
import EditColumns from '@/components/UI/EditColumns.vue';

export default {
  data () {
    return {
      config: brandConfig[currentBrand()].devices,
      deleteModal: false,
      columns: []
    };
  },
  components: {
    TableErrors,
    DeleteDevice,
    EmptyTable,
    ColorDot,
    TwoDates,
    EditColumns
  },
  created () {
    this.getColumns();
  },
  methods: {
    showDeleteModal (bool) {
      this.deleteModal = bool;
    },
    onCellClick: function (params) {
      if (params.column.field === 'delete') {
        this.$store.dispatch('getSelectedDeviceQuery', {
          deviceId: params.row.deviceId
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.showDeleteModal(true);
            }
          });
      }

      if (params.column.field === 'deviceId') {
        const {
          row: { deviceId }
        } = params;
        this.$store.dispatch('getSelectedDeviceQuery', {
          deviceId: params.row.deviceId
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.$router.push({
                name: 'deviceDetail',
                params: { deviceId },
                query: { prevPage: '/devices' }
              });
            }
          });
      }
    },
    getColumns () {
      let columnPreferences = localStorage.getItem('COLUMN_PREFERENCES_DEVICES');
      if (!columnPreferences) {
        this.columns = this.config.columns;
      }

      columnPreferences = JSON.parse(columnPreferences);
      const columnsToShow = [];
      this.config.columns.map(column => {
        const showColumn = columnPreferences.filter(field => field === column.field);
        if (showColumn.length) {
          columnsToShow.push(column);
        }
      });
      this.columns = columnsToShow;
    }
  }
};
</script>

<style>
button a.cursor-pointer {
  color: white;
  text-decoration: none;
}

button a.cursor-pointer:hover {
  color: white;
  opacity: initial;
}
</style>
