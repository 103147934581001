<template>
  <div>
    <ClaimGilletteDevice
      v-if="$store.state.brand.includes('Gillette')"
    />
    <CreateOralBDevice
      v-if="$store.state.brand.includes('Oral-B')"
    />
    <ClaimOpteDevice
      v-if="$store.state.brand === $store.state.brandConstants.opte"
    />
    <CreateLabsDevice
      v-if="$store.state.brand.includes('Labs')"
    />
    <CreateBraunDevice v-if="$store.state.brand.includes('Braun')" />
  </div>
</template>

<script>
import CreateOralBDevice from '@/components/forms/oralB/CreateOralBDevice.vue';
import ClaimOpteDevice from '@/components/forms/opte/ClaimOpteDevice.vue';
import ClaimGilletteDevice from '@/components/forms/gillette/ClaimGilletteDevice.vue';
import CreateLabsDevice from '@/components/forms/labs/CreateLabsDevice.vue';
import CreateBraunDevice from '@/components/forms/braun/CreateBraunDevice.vue';

export default {
  components: {
    CreateOralBDevice,
    ClaimOpteDevice,
    ClaimGilletteDevice,
    CreateLabsDevice,
    CreateBraunDevice
  }
};
</script>
